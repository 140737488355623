import React from 'react'
import { Tabs, Tab, Container, Card } from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { NavBar } from './navBar'

function App() {
  return (
    <>
      <NavBar key="0" />
      <div className="container">
        <Tabs
          key="1"
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
            key="2"
            eventKey="home"
            title="Информация"
          >
            <Container key="3">
              <Card key="4">
                <Card.Body key="5">
                  <Card.Body>
                    <div className="block text-section">
                      <h2>Политика конфиденциальности</h2>

                      <p>Сайт – www.interstone.su</p>
                      <p>Мобильное приложение - https://onelink.to/wdvh5a</p>
                      <p>
                        Посетитель Сайта — лицо, пришедшее на сайт
                        www.interstone.su
                      </p>
                      <p>
                        Посетитель мобильного приложения — лицо, установившее
                        себе на мобильное устройство приложение
                        https://onelink.to/wdvh5a
                      </p>
                      <p>
                        Пользователь — физическое или юридическое лицо,
                        посетитель Сайта, пользователь Мобильного приложения,
                        принимающий условия настоящего Соглашения и желающий
                        воспользоваться сервисом, услугами, товарами Компании.
                      </p>
                      <p>Компания — ТМ ИНТЕРСТОУН</p>
                      <p>
                        Политика конфиденциальности персональных данных (далее —
                        Политика) действует в отношении всей информации, которую
                        Администрация сайта и Мобильного приложения может
                        получить о Пользователе во время использования им Сайта
                        или Мобильного приложения, предоставляемого
                        Администрацией сайта и мобильного приложения, а также в
                        ходе исполнения Администрацией сайта и мобильного
                        приложения любых соглашений и договоров с Пользователем.
                      </p>
                      <p>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                      <div className="content-wrapp open">
                        <p>
                          1.1. Администрация сайта и мобильного приложения
                          обрабатывает персональные данные на законной и
                          справедливой основе для выполнения возложенных на
                          Администрацию сайта и мобильного приложения
                          законодательством функций, полномочий и обязанностей,
                          осуществления прав и законных интересов Администрации
                          сайта и Пользователей.
                        </p>
                        <p>
                          1.2. Использование Сайта, а также его отдельных
                          функций означает безоговорочное согласие Пользователя
                          с настоящей Политикой и указанными в ней условиями
                          обработки его персональных данных. В случае несогласия
                          с этими условиями Пользователь должен воздержаться от
                          использования Сайта.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          2. ОБРАБОТКА И КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ
                          ПОЛЬЗОВАТЕЛЯ
                        </p>
                        <p>
                          2.1. Настоящая Политика устанавливает обязательства
                          Администрации сайта по неразглашению и обеспечению
                          режима защиты конфиденциальности персональных данных,
                          которые Пользователь предоставляет при использовании
                          Сайта.
                        </p>
                        <p>
                          2.2. Категории персональных данных, которые
                          Администрация сайта может собирать с Пользователей:
                        </p>
                        <p>2.2.1. ФИО;</p>
                        <p>2.2.2. Название организации;&nbsp;</p>
                        <p>2.2.3. Номер телефона;&nbsp;</p>
                        <p>2.2.4. Адрес электронной почты;&nbsp;</p>
                        <p>2.2.5. Серию и номер паспорта;&nbsp;</p>
                        <p>2.2.6. Дату рождения;&nbsp;</p>
                        <p>2.2.7. Адрес места проживания.</p>
                        <p>
                          2.3. Администрация сайта защищает данные, которые
                          автоматически передаются в процессе просмотра
                          рекламных блоков и при посещении страниц на сайте
                          Сайта, на которых установлен статистический скрипт
                          системы ("пиксель").
                        </p>
                        <p>
                          2.4. Защита данных, автоматически передаваемых при
                          просмотре рекламных блоков и посещении страниц с
                          установленными на них статистическими скриптами
                          системы (пикселями) осуществляется Администрацией
                          сайта.&nbsp;
                        </p>
                        <p>Вот перечень этих данных:</p>
                        <p>2.4.1. IP-адрес;&nbsp;</p>
                        <p>2.4.2. сведения из cookies;&nbsp;</p>
                        <p>
                          2.4.3. сведения о браузере (либо другой программе,
                          через которую становится доступен показ
                          рекламы);&nbsp;
                        </p>
                        <p>2.4.4. время посещения сайта;&nbsp;</p>
                        <p>
                          2.4.5. адрес страницы, на которой располагается
                          рекламный блок;&nbsp;
                        </p>
                        <p>2.4.6. реферер (адрес предыдущей страницы).</p>
                        <p>
                          2.5. Настоящая Политика применима только к информации,
                          обрабатываемой в ходе использования Сайта.
                          Администрация сайта не контролирует и не несет
                          ответственность за обработку информации сайтами и
                          Сайтами третьих лиц, на которые Пользователь может
                          перейти по ссылкам, доступным внутри Сайта.
                        </p>
                        <p>
                          2.6. Администрация сайта не проверяет достоверность
                          персональных данных, предоставляемых Пользователем, и
                          не имеет возможности оценивать его дееспособность.
                          Однако Администрация сайта исходит из того, что
                          пользователь предоставляет достоверные и достаточные
                          персональные данные и поддерживает их в актуальном
                          состоянии. Последствия предоставления недостоверной
                          или недостаточной информации определены в
                          Пользовательском соглашении Сайта, размещенном на
                          странице Сайта.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>3. ЦЕЛИ СБОРА ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ</p>
                        <p>
                          3.1. Администрация сайта собирает и хранит только те
                          персональные данные, которые необходимы для
                          предоставления полного или частичного функционала
                          Сайта или исполнения соглашений и договоров с
                          Пользователем, за исключением случаев, когда
                          законодательством предусмотрено обязательное хранение
                          персональных данных в течение определенного законом
                          срока.
                        </p>
                        <p>
                          3.2. Администрация сайта обрабатывает персональные
                          данные Пользователя в следующих целях:
                        </p>
                        <p>
                          3.2.1. Идентифицировать Пользователя, который оставил
                          заявку на товар, услугу, рекламу на сайте.
                        </p>
                        <p>
                          3.2.2. Установить с Пользователем обратную связь, под
                          которой подразумевается, в частности, рассылка
                          запросов и уведомлений, касающихся использования
                          сайта, обработка пользовательских запросов и заявок,
                          оказание прочих услуг.
                        </p>
                        <p>
                          3.2.3. Определить местонахождение Пользователя, чтобы
                          обеспечить корректное оказание услуг Компании.
                        </p>
                        <p>
                          3.2.4. Подтвердить, что данные, которые предоставил
                          Пользователь, полны и достоверны.
                        </p>
                        <p>
                          3.2.5. Своевременно информировать Пользователя об
                          обновлённой продукции, ознакомлять его с уникальными
                          предложениями, новыми прайсами, новостями о
                          деятельности Компании или его партнёров компании и
                          прочими сведениями, если Пользователь изъявит на то
                          своё согласие.
                        </p>
                        <p>
                          3.2.6. Рекламировать товары и услуги компании, если
                          Пользователь изъявит на то своё согласие.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          4. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ
                        </p>
                        <p>
                          4.1. Обработка персональных данных Пользователя
                          осуществляется без ограничения срока, любым законным
                          способом, в том числе в информационных системах
                          персональных данных с использованием средств
                          автоматизации или без использования таких средств.
                        </p>
                        <p>
                          4.2. В отношении персональных данных Пользователя
                          сохраняется их конфиденциальность, кроме случаев
                          добровольного предоставления Пользователем информации
                          о себе для общего доступа неограниченному кругу лиц.
                          При использовании отдельных функций Сайта,
                          Пользователь соглашается с тем, что определённая часть
                          его персональной информации становится общедоступной.
                        </p>
                        <p>
                          4.3. При обработке персональных данных Пользователей
                          Администрация сайта руководствуется:
                        </p>
                        <p>
                          4.3.1. Федеральным законом от 27.07.2006 г. № 152-ФЗ
                          «О персональных данных»;
                        </p>
                        <p>
                          4.3.2. Постановлением Правительства Российской
                          Федерации от 01.11.2012 г. № 1119 «Об утверждении
                          требований к защите персональных данных при их
                          обработке в информационных системах персональных
                          данных»;
                        </p>
                        <p>
                          4.3.3. Постановлением Правительства Российской
                          Федерации от 15.09.2008 г. № 687 «Об утверждении
                          Положения об особенностях обработки персональных
                          данных, осуществляемой без использования средств
                          автоматизации»;
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
                        </p>
                        <p>
                          5.1. Срок обработки персональных данных Пользователя
                          ничем не ограничен. Процедура обработки может
                          проводиться любым предусмотренным законодательством
                          способом. В частности, с помощью информационных систем
                          персональных данных, которые могут вестись
                          автоматически либо без средств автоматизации.
                        </p>
                        <p>
                          5.2. Обработанные Администрацией сайта персональные
                          данные Пользователя могут передаваться третьим лицам,
                          в число которых входят курьерские службы, организации
                          почтовой связи, операторы электросвязи. Делается это
                          для того, чтобы выполнить заказ Пользователя и\или
                          доставить документы по адресу. Согласие Пользователя
                          на подобную передачу предусмотрено правилами политики
                          сайта.
                        </p>
                        <p>
                          5.3. Также обработанные Администрацией сайта
                          персональные данные могут передаваться уполномоченным
                          органов государственной власти Российской Федерации,
                          если это осуществляется на законных основаниях и в
                          предусмотренном российским законодательством порядке.
                        </p>
                        <p>
                          5.4. Если персональные данные будут утрачены или
                          разглашены, Пользователь уведомляется об этом
                          Администрацией сайта.
                        </p>
                        <p>
                          5.5. Все действия Администрации сайта направлены на
                          то, чтобы не допустить к персональным данным
                          Пользователя третьих лиц (за исключением п.п. 5.2,
                          5.3). Последним эта информация не должна быть доступна
                          даже случайно, дабы те не уничтожили её, не изменили и
                          не блокировали, не копировали и не распространяли, а
                          также не совершали прочие противозаконные действия.
                          Для защиты пользовательских данных Администрация
                          располагает комплексом организационных и технических
                          мер.
                        </p>
                        <p>
                          5.6. Если персональные данные будут утрачены либо
                          разглашены, Администрация сайта совместно с
                          Пользователем готова принять все возможные меры, дабы
                          предотвратить убытки и прочие негативные последствия,
                          вызванные данной ситуацией.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          6. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ ПРИ ПОМОЩИ ФАЙЛОВ
                          COOKIE
                        </p>
                        <p>
                          6.1. Файлы cookie, передаваемые Администрации сайта
                          техническим устройствам Пользователя, могут
                          использоваться для предоставления Пользователю
                          персонализированных функций Сайта, для персональной
                          рекламы, которая показывается Пользователю, в
                          статистических и исследовательских целях, а также для
                          улучшения Сайта.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>7. ОБЯЗАТЕЛЬСТВА СТОРОН</p>
                        <p>7.1. В обязанности Пользователя входит:</p>
                        <p>
                          7.1.1. Сообщение соответствующих требованиям сайта
                          сведений о себе.
                        </p>
                        <p>
                          7.1.2. Обновление и дополнение предоставляемых им
                          сведений в случае изменения таковых.
                        </p>
                        <p>7.2. В обязанности Администрации сайта входит:</p>
                        <p>
                          7.2.1. Применение полученных сведений исключительно в
                          целях, обозначенных в п. 3 существующей Политики
                          конфиденциальности.
                        </p>
                        <p>
                          7.2.2. Обеспечение конфиденциальности поступивших от
                          Пользователя сведений. Они не должны разглашаться,
                          если Пользователь не даст на то письменное разрешение.
                          Также Администрация не имеет права продавать,
                          обменивать, публиковать либо разглашать прочими
                          способами переданные Пользователем персональные
                          данные, исключая п.п. 5.2 и 5.3 существующей Политики
                          конфиденциальности.
                        </p>
                        <p>
                          7.2.3. Принятие мер предосторожности, дабы
                          персональные данные Пользователя оставались строго
                          конфиденциальными, точно также, как остаются
                          конфиденциальными такого рода сведения в современном
                          деловом обороте.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          7.2.4. Блокировка персональных пользовательских данных
                          с того момента, с которого Пользователь либо его
                          законный представитель сделает соответствующий запрос.
                          Право сделать запрос на блокировку также
                          предоставляется органу, уполномоченному защищать права
                          Пользователя, предоставившего Администрации сайта свои
                          данные, на период проверки, в случае обнаружения
                          недостоверности сообщённых персональных данных либо
                          неправомерности действий.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>8. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
                        <p>
                          8.1. В случае неисполнения Администрацией сайта
                          собственных обязательств и, как следствие, убытков
                          Пользователя, понесённых из-за неправомерного
                          использования предоставленной им информации,
                          ответственность возлагается на неё. Об этом, в
                          частности, утверждает российское законодательство.
                          Исключение существующая в настоящее время Политика
                          конфиденциальности делает для случаев, отражённых в
                          п.п. 5.2, 5.3 и 7.2.
                        </p>
                        <p>
                          8.2. Но существует ряд случаев, когда Администрация
                          сайта ответственности не несёт, если пользовательские
                          данные утрачиваются или разглашаются. Это происходит
                          тогда, когда они:
                        </p>
                        <p>
                          8.2.1. Превратились в достояние общественности до
                          того, как были утрачены или разглашены.
                        </p>
                        <p>
                          8.2.2. Были предоставлены третьими лицами до того, как
                          их получила Администрация сайта.
                        </p>
                        <p>8.2.3. Разглашались с согласия Пользователя.</p>
                        <p>
                          <br />
                        </p>
                        <p>9. РАЗРЕШЕНИЕ СПОРОВ</p>
                        <p>
                          9.1. Если Пользователь недоволен действиями
                          Администрации сайта и намерен отстаивать свои права в
                          суде, до того, как обратиться с иском, он в
                          обязательном порядке должен предъявить претензию
                          (письменно предложить урегулировать конфликт
                          добровольно).
                        </p>
                        <p>
                          9.2. Получившая претензию Администрация обязана в
                          течение 30 календарных дней с даты её получения
                          письменно уведомить Пользователя о её рассмотрении и
                          принятых мерах.
                        </p>
                        <p>
                          9.3. Если обе стороны так и не смогли договориться,
                          спор передаётся в судебный орган, где его должны
                          рассмотреть согласно действующему российскому
                          законодательству.
                        </p>
                        <p>
                          9.4. Регулирование отношений Пользователя и
                          Администрации сайта в Политике конфиденциальности
                          проводится согласно действующему российскому
                          законодательству.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>10. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</p>
                        <p>
                          10.1. Администрация сайта вправе менять существующую
                          на текущий момент Политику конфиденциальности, не
                          спрашивая согласия у Пользователя.
                        </p>
                        <p>
                          10.2. Вступление в силу новой Политики
                          конфиденциальности начинается после того, как
                          информация о ней будет выложена на сайт Компании, если
                          изменившаяся Политика не подразумевает иного варианта
                          размещения.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>11. ОБРАТНАЯ СВЯЗЬ. ВОПРОСЫ И ПРЕДЛОЖЕНИЯ</p>
                        <p>
                          11.1. Все предложения или вопросы по поводу настоящей
                          Политики Пользователь вправе направлять в Службу
                          поддержки пользователей Администрации сайта по
                          электронной почте{' '}
                          <a href="mailto:info@interstone.su">
                            info@interstone.su
                          </a>
                          .&nbsp;
                        </p>
                      </div>

                      <div id="terms-site"></div>
                    </div>
                    <div className="block text-section">
                      <h2>Условия использования материалов сайта</h2>

                      <p>1.ОБЩИЕ ПОЛОЖЕНИЯ</p>
                      <p>
                        1.1. Авторские права на сайт, как сложный со ставной
                        объект, как в целом, так и на все его части, включая
                        программную часть, код, дизайн, скрипты,
                        автоматизирующие какие-либо процессы на сайте, текстовые
                        материалы, изображения, фотографии, рисунки, схемы,
                        аудио- и видеоматериалы, и др. принадлежит ООО
                        «ИНТЕРСТОУН» (далее Правообладатель).{' '}
                      </p>
                      <div className="content-wrapp open">
                        <p>
                          1.2. Данные Правила определяют условия и порядок
                          использования материалов сайта www.interstone.su и
                          мобильного приложения{' '}
                          <a
                            href="https://onelink.to/wdvh5a"
                            title="https://onelink.to/wdvh5a"
                          >
                            <u>https://onelink.to/wdvh5a</u>
                          </a>{' '}
                          к которым относятся элементы сайта и мобильного
                          приложения : текстовые материалы, изображения,
                          фотографии, рисунки, схемы, аудио- и видеоматериалы
                          (далее – Материалы).
                        </p>
                        <p>
                          1.3. Любое использование Материалов допускается не
                          иначе как с письменного разрешения их правообладателя,
                          если иное не установлено настоящими Правилами.
                        </p>
                        <p>
                          1.4. Под использованием понимается любое
                          воспроизведение, распространение, доведение до
                          всеобщего сведения, сообщение по кабелю и в эфир,
                          импорт, прокат, публичное исполнение, перевод и другая
                          переработка Материалов и любые другие способы
                          использования, независимо от того, совершаются ли
                          соответствующие действия в целях извлечения прибыли
                          или без такой цели, осуществляется ли использование
                          Материалов в полном объеме или частично.
                        </p>
                        <p>
                          1.5. Использование Материалов сайта и мобильного
                          приложения с нарушением любого из условий данных
                          Правил означает, что Материалы используются
                          неправомерно, что является нарушением прав
                          Правообладателя и/или третьих лиц и влечет за собой
                          ответственность, предусмотренную действующим
                          законодательством РФ.
                        </p>
                        <p>
                          1.6. Действие настоящих Правил распространяется на
                          любых посетителей сайта и мобильного приложения,
                          информационные агентства, газеты, журналы и прочие
                          печатные издания, электронные СМИ, любые иные средства
                          массовой информации, а также интернет-сайты, не
                          зарегистрированные в качестве СМИ, на физических лиц,
                          организации и общественные объединения, любых других
                          третьих лиц (далее – "Пользователи").
                        </p>
                        <p>
                          1.7. Настоящие правила обязательны для всех
                          Пользователей сайта и мобильного приложения .
                          Правообладатель может в любое время изменить любой
                          пункт настоящих Правил без специального письменного
                          уведомления Пользователей путем публикации новой
                          редакции настоящих Правил на сайте и мобильном
                          приложении.{' '}
                        </p>
                        <p>
                          1.8. Все Пользователи обязаны ознакомиться с текстом
                          настоящих Правил.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p> </p>
                        <p>2. ИСПОЛЬЗОВАНИЕ МАТЕРИАЛОВ САЙТА</p>
                        <p>
                          2.1. Любой Пользователь вправе свободно (без
                          разрешения Правообладателя и без выплаты
                          вознаграждения):
                        </p>
                        <p>
                          2.1.1. использовать в любом количестве Материалы сайта
                          и мобильного приложения для составления обзоров в виде
                          кратких аннотаций Материалов, в объеме, оправданном
                          целью создания обзора. Воспроизведение или иное
                          использование каждого конкретного Материала в полном
                          объеме не допускается. Данные аннотации должны
                          содержать в себе ссылку на источник в порядке,
                          установленном п. 4.2. настоящих Правил;
                        </p>
                        <p>
                          2.1.2. цитировать любое количество Материалов сайта и
                          мобильного приложения в научных, полемических,
                          критических или информационных целях в объеме,
                          оправданном целью цитирования. Подобное цитирование
                          должно содержать в себе ссылку на источник в порядке,
                          установленном п. 4.2. настоящих Правил;
                        </p>
                        <p>
                          2.1.3. свободно использовать Материалы в иных случаях,
                          прямо предусмотренных ГК РФ.
                        </p>
                        <p>
                          2.2. Допускается использование Материалов сайта и
                          мобильного приложения без разрешения Правообладателя и
                          без выплаты вознаграждения следующим Пользователям:
                        </p>
                        <p>
                          2.2.1. физическим и юридическим лицам, упомянутым в
                          материалах сайта и мобильного приложения. Такие лица
                          имеют право воспроизводить, распространять, сообщать в
                          эфир или по кабелю, доводить до всеобщего сведения
                          любые Материалы, содержащие такие упоминания, без
                          выплаты вознаграждения Правообладателю и получения
                          письменного разрешения Правообладателя.
                        </p>
                        <p>
                          2.3. В любом случае использование Материалов,
                          предусмотренное пп.2.1. – 2.2. настоящих Правил, не
                          должно ущемлять необоснованным образом законные права
                          и интересы Правообладателя и/или третьих лиц. Данное
                          использование осуществляется с учетом ограничений,
                          установленных разделом 3 настоящих Правил.
                        </p>
                        <p>
                          2.4. Во всех иных случаях, помимо пп.2.1. – 2.2.
                          Правил, использование Материалов осуществляется на
                          основании письменного разрешения Правообладателя.
                        </p>
                        <p>
                          2.5. Правообладатель имеет право отказать любому
                          Пользователю в выдаче разрешения на использования
                          Материалов сайта, без объяснения причин такого отказа.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p> </p>
                        <p>3. ОГРАНИЧЕНИЯ ИСПОЛЬЗОВАНИЯ МАТЕРИАЛОВ</p>
                        <p>Настоящими Правилами запрещается:</p>
                        <p>
                          3.1. Использование Материалов или их фрагментов без
                          разрешения Правообладателя иными способами и в иных
                          случаях, кроме указанных в пп. 2.1.- 2.2. настоящих
                          Правил.
                        </p>
                        <p>
                          3.2. Использование Материалов согласно пп. 2.1. – 2.2.
                          Правил при создании составных произведений (в том
                          числе баз данных, сайтов, справочников, рекламных
                          материалов и пр.).
                        </p>
                        <p>
                          3.3. Использование Материалов согласно пп. 2.1. – 2.2.
                          Правил с целью извлечения прибыли.
                        </p>
                        <p>
                          3.4. Использование Материалов способами и в объеме,
                          прямо не разрешенными данными Правилами, действующим
                          законодательством РФ, а равно с нарушением настоящих
                          Правил и действующего законодательства РФ.
                        </p>
                        <p>
                          {' '}
                          <br />
                        </p>
                        <p>
                          4. ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЕЙ ПРИ ИСПОЛЬЗОВАНИИ
                          МАТЕРИАЛОВ
                        </p>
                        <p>
                          При использовании Материалов сайта и мобильного
                          приложения в полном объеме или частично каждый
                          Пользователь обязан:
                        </p>
                        <p>
                          4.1. Получить письменное разрешение Правообладателя на
                          использование Материалов, обратившись на следующий
                          адрес электронной почты:{' '}
                          <a href="mailto:info@interstone.su">
                            info@interstone.su
                          </a>{' '}
                          (за исключением случаев, предусмотренных пп.2.1. –
                          2.2. Правил).
                        </p>
                        <p>
                          4.2. Оформлять ссылки на источник Материалов (сайт
                          www.interstone.su) следующим образом: "Источник:
                          www.interstone.su".
                        </p>
                        <p>
                          4.2.1. При воспроизведении и ином использовании
                          Материалов сайта и мобильного приложения в печатном
                          виде необходимо указывать: источник в соответствии с
                          п. 4.2., автора(ов) материала (при его (их) наличии) и
                          дату размещения Материала на сайте).
                        </p>
                        <p>
                          4.2.2. При воспроизведении и ином использовании
                          материалов сайта.ru в сети Интернет, необходимо
                          указывать: источник в соответствии с п. 4.2.,
                          автора(ов) (при его (их) наличии), дату размещения
                          Материала на сайте и активную гиперссылку на сайт
                          www.interstone.su, не закрытая от индексации для
                          поисковых систем.
                        </p>
                        <p>
                          4.3. Соблюдать неприкосновенность Материалов, не
                          допуская их искажения.
                        </p>
                        <p>
                          4.3.1. При воспроизведении Материалов и их ином
                          использовании не допускается переработка их
                          оригинального текста. Сокращение или перекомпоновка
                          частей Материалов, снабжение их иллюстрациями,
                          предисловием, послесловием, комментариями или какими
                          бы то ни было пояснениями, допускается только в той
                          мере, в какой это не приводит к искажению смысла
                          Материалов.
                        </p>
                        <p>
                          4.3.2. Ответственность за искажение смысла Материалов,
                          возникшее вследствие их неточного воспроизведения,
                          несет Пользователь.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p> </p>
                        <p>
                          5. ОТВЕТСТВЕННОСТЬ ЗА НЕПРАВОМЕРНОЕ ИСПОЛЬЗОВАНИЕ
                          МАТЕРИАЛОВ
                        </p>
                        <p>
                          5.1. Любое неправомерное использование Материалов
                          сайта и мобильного приложения является нарушением прав
                          Правообладателя и/или третьих лиц и влечет за собой
                          уголовную, гражданско-правовую и иную предусмотренную
                          действующим законодательством РФ ответственность.
                        </p>
                        <p>
                          5.2. В случае предъявления к Правообладателю претензий
                          со стороны каких-либо третьих лиц, чьи права нарушены
                          неправомерными действиями Пользователя, а также
                          получения предписаний, предупреждений и иных актов
                          уполномоченных органов государственной власти,
                          вовлечения Правообладателя в судебный процесс в
                          результате неправомерных действий Пользователя,
                          Пользователь возмещает Правообладателю все причиненные
                          тем самым убытки.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p> </p>
                        <p>6. ПРОЧИЕ ВОПРОСЫ</p>
                        <p>
                          6.1. По любым вопросам, связанным с использованием
                          материалов сайта Вы можете обратиться по E-mail:{' '}
                          <a href="mailto:info@interstone.su">
                            info@interstone.su
                          </a>
                        </p>
                        <p>
                          6.2. Данные Правила доступны для всеобщего
                          ознакомления на сайте www.interstone.su
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Card.Body>
              </Card>
            </Container>
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

export default App
